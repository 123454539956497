import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import favicon from '../../assets/images/aa/favicon.png'
import matchdocs from '../../assets/images/ba/book-appointment.png'
import app from '../../assets/images/ba/appointment-app.png'

import bookform from '../../assets/images/ba/book-form.jpg'
import doctors from '../../assets/images/ba/doctors.jpg'
import evaluation from '../../assets/images/ba/evaluation.jpg'
import schedules from '../../assets/images/ba/schedules.jpg'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Text variant="tag">PWA</Text>
                                <Title mb="0.5em">Book Appointments</Title>
                                <Text variant="p" mb="1.6em">
                                    This micro-site was designed to book
                                    appointments for Atlantic Spine Center.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Using gatsby and react for the front end, I
                                    updated the user interfaces from pain
                                    diagnosed appointment booking to give a
                                    zocdoc like look and feel.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    The site was designed to replace zocdoc for
                                    booking appointments from Google Business
                                    placement.
                                </Text>
                            </Col>
                            <Col lg="4">
                                <img
                                    src={app}
                                    alt="Appointment Progress Web Application"
                                    className="img-fluid w-100"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Atlantic Spine Center
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Spring 2022
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://book.atlanticspinecenter.com/"
                                    >
                                        Book Appointment
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="5" className="mb-5 pr-lg-5">
                                <img
                                    src={matchdocs}
                                    alt="Match Docs"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="7" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    Details Matter
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    When it comes to progressive web apps every
                                    detail matters. The most important detail in
                                    modern javascript applications are its
                                    dependencies.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Keeping the sites dependencies up to date
                                    can be a challenging detail a lot of
                                    businesses overlook.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Open source projects like gatsby and react
                                    have helped provide developers like me a
                                    robust framework for creating todays modern
                                    Progress Web Applications.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Keys mt="-5%">
                    <Key name="gatsby" title="01. Gatsby" left>
                        Gatsby continues to be at the top of my key developments
                        because it keeps getting better every release. This
                        appointment booking site is running the latest{' '}
                        <a
                            href="https://www.gatsbyjs.com/gatsby-4/"
                            rel="noreferrer noopener"
                        >
                            gatsby v4
                        </a>
                        .
                    </Key>

                    <Key name="react" title="02. React">
                        Gatsby wouldn't be anywhere without mentioning react and
                        the latest updates from react.
                    </Key>

                    <Key name="twilio" title="03. Twilio" left>
                        The site features a twilio powered dynamic phone
                        tracking system for marketing campaigns. When you change
                        the utc tag the phone numbers change to match.
                    </Key>

                    <Key name="salesforce" title="04. Salesforce">
                        Salesforce and Zapier were used for submitting leads
                        through the various forms on the site. I also used
                        Salesforce to track over 2 dozen dynamic phone numbers.
                    </Key>

                    <Key name="mobile" title="05. Mobile Compatability" left>
                        With over 80% of the websites traffic the website is
                        designed to support and work well on the smallest of
                        mobile screens. 320px is usually a good minimum size to
                        develop progress web applications.
                    </Key>

                    <Key name="googlecloud" title="06. Google Cloud">
                        Google's new Cloud Platform has a suite of simple
                        services like Cloud Run and App Engine that have made
                        deploying static sites like this one extremly simple.
                    </Key>
                </Keys>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={bookform}
                                        alt="Booking Form"
                                    />
                                    <Screenshot
                                        src={doctors}
                                        alt="Doctors Landing Page"
                                    />
                                    <Screenshot
                                        src={evaluation}
                                        alt="Canidate Check Evaluation"
                                    />
                                    <Screenshot
                                        src={schedules}
                                        alt="Doctors Schedules"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/pain-diagnosed/">
                                Pain Diagnosed
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
